"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_qr_reader_1 = require("react-qr-reader");
var QRCodeScanner = function (_a) {
    var onScan = _a.onScan;
    var handleScan = (0, react_1.useCallback)(function (data) {
        data && onScan(data);
    }, [onScan]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(react_qr_reader_1.QrReader, { scanDelay: 0, containerStyle: { width: '325px', height: '305px' }, videoContainerStyle: { width: '325px', height: '305px' }, videoStyle: { width: '325px', height: '305px' }, onResult: function (result, error) {
                !!result && setTimeout(function () { return handleScan(result === null || result === void 0 ? void 0 : result.text); }, 500);
                !!error && console.log('qr_error', error);
            }, constraints: { facingMode: 'environment' } }) }));
};
exports.default = QRCodeScanner;
